<template>
  <div>
    <el-dialog
      v-model="visible"
      @open="onOpen"
      @close="onClose"
      title="添加污染物"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="mini"
        label-width="px"
        label-position="left"
      >
        <el-form-item
          label-width="150px"
          label="污染物编号(2005)"
          prop="pollutionCode2005"
        >
          <el-input
            v-model="formData.pollutionCode2005"
            placeholder="请输入污染物编号(2005)"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item
          label-width="150px"
          label="污染物编号(2017)"
          prop="pollutionCode2017"
        >
          <el-input
            v-model="formData.pollutionCode2017"
            placeholder="请输入污染物编号(2017)"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <div>
          <slot name="footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
          </slot>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { addPollutionLink } from "@/api/pollution-link";

export default {
  name: "PollutionLinkAdd",
  components: {},
  props: { dialogVisible: Boolean },
  data() {
    return {
      visible: false,
      formData: {
        pollutionCode2005: "",
        pollutionCode2017: "",
      },
      rules: {
        pollutionCode2005: [
          {
            required: true,
            message: "请输入污染物编号(2005)",
            trigger: "blur",
          },
        ],
        pollutionCode2017: [
          {
            required: true,
            message: "请输入污染物编号(2017)",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {
    dialogVisible: {
      handler(value) {
        this.visible = value;
      },
    },
  },
  created() {},
  mounted() {
    this.visible = this.dialogVisible;
  },
  methods: {
    onOpen() {},
    onClose() {
      this.$refs["elForm"].resetFields();
      this.$emit("updateVisible");
    },
    close() {
      this.$emit("updateVisible");
    },
    handleConfirm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) {
          return;
        }
        const requestBody = {
          id: undefined,
          pollutionCode2005: this.formData.pollutionCode2005,
          pollutionCode2017: this.formData.pollutionCode2017,
        };
        addPollutionLink(requestBody).then(() => {
          this.$parent.getList();
        });
        this.close();
      });
    },
  },
};
</script>
<style></style>
