import request from "@/utils/request";

// 获取table
export function getTable(params) {
  return request({
    url: "/pollution-link",
    method: "Get",
    params: params,
  });
}

// 增加
export function addPollutionLink(requestBody) {
  return request({
    url: "/pollution-link",
    method: "Post",
    data: requestBody,
  });
}

// 编辑
export function editPollutionLink(requestBody) {
  return request({
    url: "/pollution-link",
    method: "Patch",
    data: requestBody,
  });
}

// 删除
export function deletePollutionLink(params) {
  return request({
    url: "/pollution-link",
    method: "Delete",
    params: params,
  });
}
